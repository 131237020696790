import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundColor from "../components/CTA/CTABackgroundColor";
import InstagramFeed from "../components/Repeating/InstagramFeed";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Professional Dog Grooming San Diego | Bella Woof"
            description="At Bella Woof, we offer a variety of professional dog grooming services in San Diego to treat your pets! Learn more & schedule an appointment today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <div className="container">
            <section className="pt-2 md:pt-20 pb-20 md:pb-28">
               <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-16 lg:gap-x-28 items-center">
                  <div className="order-2 md:order-1">
                     <h1>Professional Dog Grooming for Your Pups</h1>
                     <p>
                        Why should humans have all the fun? At Bella Woof, we offer a variety of professional grooming services to treat your pets!
                        With no cages and working with only one dog at a time, our experienced groomers ensure that your pet has a stress-free
                        grooming experience. Make your pets look and feel their best. Ready to pamper your pup? Check out our list of grooming
                        services below.
                     </p>
                     <ButtonSolid href="tel:+1-619-237-0730" text="(619) 237-0730" className="gtm-phone-number" />
                  </div>
                  <div className="order-1 md:order-2">
                     <Img fluid={data.intro.childImageSharp.fluid} alt="Professional Dog Grooming for Your Pups" />
                  </div>
               </div>
            </section>

            <section className="bg-primary_lighter rounded-3xl pt-20 md:pt-12 pb-10 md:pb-18 px-6 md:px-14 lg:px-22 mb-20 md:mb-32">
               <p className="text-mobile8xl md:text-8xl font-heading leading-none font-bold text-primary text-opacity-30 mb-14 md:mb-24">
                  Grooming List
               </p>

               <header className="max-w-xl mb-12">
                  <h3>Grooming Packages Available</h3>
                  <p>Find the perfect package for your canine’s needs! For current pricing, please call Bella Woof at 619-237-0730.</p>
               </header>
               <div className="grid md:grid-cols-2 md:gap-x-20 mb-4 md:mb-14">
                  <div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Express Bath</p>
                        <ul>
                           <li className="mb-3">Shampoo, Condition, Hand Dry, Brushout</li>
                        </ul>
                     </div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Premium Bath</p>
                        <ul>
                           <li className="mb-3">Express Bath plus Nail Trim (Grinding), Gland Expression, Clean Teeth and Ears</li>
                        </ul>
                     </div>
                  </div>
                  <div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Mini Groom</p>
                        <ul>
                           <li className="mb-3">Premium Bath plus Face, Feet and Sanitary Haircut</li>
                        </ul>
                     </div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Full Groom</p>
                        <ul>
                           <li className="mb-3">Premium Bath plus Full Body Haircut</li>
                        </ul>
                     </div>
                  </div>
               </div>

               <header className="max-w-xl mb-12">
                  <h3>Grooming Services</h3>
               </header>
               <div className="grid md:grid-cols-2 md:gap-x-20">
                  <div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Haircuts + Trims</p>
                        <ul>
                           <li className="mb-3">Full Body Haircut</li>
                           <li className="mb-3">Scissoring hair around eyes, mouth & ears</li>
                           <li className="mb-3">Face, Feet and Sanitary Haircut</li>
                           <li className="mb-3">Paw Trimming and Cleaning</li>
                        </ul>
                     </div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Maintenance</p>
                        <ul>
                           <li className="mb-3">Gland Expression (external only)</li>
                           <li className="mb-3">Ear Cleaning</li>
                           <li className="mb-3">Teeth Brushing & Cleaning</li>
                           <li className="mb-3">Nail Trimming/Grinding/Filing</li>
                        </ul>
                     </div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Treatments</p>
                        <ul>
                           <li className="mb-3">Skunk Treatment</li>
                           <li className="mb-3">Flea & Tick Treatment</li>
                        </ul>
                     </div>
                  </div>
                  <div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Specialty Shampoos + Finishes</p>
                        <ul>
                           <li className="mb-3">Keratin Shampoo</li>
                           <li className="mb-3">Tar & Sulfur Shampoo</li>
                           <li className="mb-3">Medicated Shampoo - Soothe w/ Chlorhexidine</li>
                           <li className="mb-3">Mud Bath</li>
                           <li className="mb-3">Hypoallergenic Shampoo</li>
                           <li className="mb-3">Blueberry Facial</li>
                           <li className="mb-3">De-Shed Shampoo</li>
                        </ul>
                     </div>
                     <div className="mb-12">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-7">Baths + Brushouts</p>
                        <ul>
                           <li className="mb-3">Bath</li>
                           <li className="mb-3">Shampoo and Condition (with premium products)</li>
                           <li className="mb-3">Hand Dry, Crate-free, No Cage Dryer</li>
                           <li className="mb-3">De-matting</li>
                           <li className="mb-3">Brushout</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-12 gap-y-8 items-center">
                  <div className="md:col-start-1 md:col-span-5 md:pr-24 order-2 md:order-1">
                     <h2>About Our Services</h2>
                     <p className="mb-0">
                        At Bella Woof, we treat dogs like family! Our professional groomers and bathers also understand how unique each pet is.
                        Grooming quotes are based on your pet’s breed, size, behavior and coat condition. Additional fees may apply for matting or
                        difficult dogs. We also offer nail trim service for cats, by appointment. Your pets deserve experienced professionals and we
                        look forward to pampering them with expert grooming, premium products, and all the love.
                     </p>
                  </div>
                  <div className="md:col-end-13 md:col-span-7 order-1 md:order-2">
                     <Img fluid={data.services.childImageSharp.fluid} alt="Premium Pet Products for Dogs & Cats" className="rounded-3xl" />
                  </div>
               </div>
            </section>
         </div>

         <WhyUs className="mb-20 md:mb-32" />

         <About className="mb-20 md:mb-32" />

         <div className="container">
            <section className="bg-secondary_light border border-solid border-secondary rounded-lg px-6 pt-10 md:pt-20 pb-14 md:pb-20 mb-20 md:mb-32">
               <div className="max-w-3xl mx-auto">
                  <img className={`mb-6 mx-auto`} src={data.info.publicURL} alt="Info" />
                  <header className="text-center">
                     <h4 className="text-lg mb-7">Grooming Cancellation/No-Show Policy</h4>
                     <p className="mb-0">
                        No cancellation fee, if 1+ day notice & rescheduled to new day. For "same-day cancellation" or "no-show", you must pay full
                        price in advance to book a new grooming appointment. At our discretion, a fee (50% of scheduled groom price) may also be
                        applied while scheduling a new appointment.
                     </p>
                  </header>
               </div>
            </section>
         </div>

         <TestimonialBackgroundImage />

         <CTABackgroundColor>
            <h2>Train with us!</h2>
            <p>
               Whether you have a puppy or an adult furry friend, we have classes in different locations to suit their needs. Get in touch to book
               training today!
            </p>
         </CTABackgroundColor>

         <InstagramFeed />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Grooming_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Grooming_Twitter.jpg" }) {
         publicURL
      }
      intro: file(relativePath: { eq: "grooming/1.0-Grooming-Intro.png" }) {
         childImageSharp {
            fluid(maxWidth: 1256, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      services: file(relativePath: { eq: "grooming/About Our Services.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1392, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      info: file(relativePath: { eq: "global/info.svg" }) {
         publicURL
      }
   }
`;

export default Page;
